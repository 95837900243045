
//公共库
import { cloneDeep } from "lodash";
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import { getShopPropconfList } from "@/api/request/item";
import { editTaskDaily, getTaskDailyList } from "@/api/request/tsak";

//组件
@Component({
  name: "TaskDaily",
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private itemList: any[] = [];
  private listLoading: boolean = false;

  //创建
  created() {
    //获取道具列表
    this.getItemList();

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getTaskDailyList({});

    //数据赋值
    this.list = data;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取道具列表
  private async getItemList() {
    //显示等待
    this.listLoading = true;

    //请求参数
    const reqParams = {
      //请求参数
      id: 0,
      giftid: 0,
      model: 0,
      propname: "",
      giftname: "",

      //页码数据
      page: 1,
      row: GFunc.getListRowLimit(),
    };

    //获取数据
    const { data } = await getShopPropconfList(reqParams);

    //数据赋值
    this.itemList = data.list;

    //插入数据
    this.itemList.unshift({ id: 1004, propname: "用户经验值" });
    this.itemList.unshift({ id: 1001, propname: "钻石" });
    this.itemList.unshift({ id: 0, propname: "请选择" });
  }

  //编辑用户
  private handleUpdate(row: any): void {
    //数据赋值
    this.dialogStatus = "update";
    this.propData = cloneDeep(row.prize);
    this.addForm = Object.assign({}, row);

    //显示对话框
    this.dialogVisible = true;
  }

  //获取奖励列表
  private getItemDetail(prize: any[]): any[] {
    //定义变量
    var giftList: any[] = [];

    //数据赋值
    for (var i: number = 0; i < prize.length; i++) {
      //数据赋值
      var gift_name: string = "";
      for (var j: number = 0; j < this.itemList.length; j++) {
        if (prize[i].gift_id == this.itemList[j].id) {
          gift_name = this.itemList[j].propname;
          break;
        }
      }

      //数据赋值
      giftList.push({ gift_name: gift_name, gift_id: prize[i].gift_id, gift_num: prize[i].gift_num });
    }

    //返回结果
    return giftList;
  }

  //------------------------------------- 添加/创建 -------------------------------------
  //定义变量
  private propData: any[] = [];
  private dialogStatus: string = "";
  private dialogVisible: boolean = false;
  private titleMap: any = { update: "编辑奖励" };

  //添加数据
  private addForm: any = {
    num: 0, //次数
    sort: 0, //排序
    mark: "0", //标志
    prize: [], //奖励
    title: "", //标题
    done_num: 0, //已完成次数
    complete: true, //是否签到
  };

  //添加道具
  private addProp(): void {
    //数据赋值
    this.propData.push({ gift_id: 0, gift_num: 0 });
  }

  //创建任务
  private async createData() {}

  //更新任务
  private async updateData() {
    //数据赋值
    const props: any[] = this.getPropData();

    //判断提示
    if (props.length == 0) return Message({ message: "请设置奖励道具！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.prize = props;

    //请求编辑
    await editTaskDaily(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取数据
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "更新成功",
      type: "success",
      duration: 2000,
    });
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //获取有效道具
  private getPropData(): any[] {
    //数据赋值
    const props = [];
    for (let i = 0; i < this.propData.length; i++) {
      //数据赋值
      const prop = this.propData[i];
      const key = GFunc.checkint(prop.gift_id);
      const value = GFunc.checkint(prop.gift_num);
      if (!key || !value) continue;

      //有效数据
      props.push({ gift_id: key, gift_num: value });
    }

    //返回结果
    return props;
  }

  //移除道具
  private removeProp(item: any): void {
    //显示提示
    MessageBox.confirm("确定删除该道具吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        //数据赋值
        const index = this.propData.indexOf(item);
        if (index !== -1) {
          this.propData.splice(index, 1);
        }
      })
      .catch((err) => {
        //打印错误
        console.log(err);
      });
  }
}
